.available__box {
	box-shadow: 0 0 10px 0px rgb(0 0 0 / 20%);
	display: block;
}

@font-face {
font-family: myFont;
src: url('../images/swash.ttf');
}

.available__text {
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: myFont;
	padding-top: 1%;
	color: #181818;
}

.box__text {
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 1.2vw;
	font-family: 'Lato', sans-serif;
	padding-top: 1%;
	color: #181818;
}

.info__text {
	font-size: 1.2vw;
	font-family: 'Lato', sans-serif;
	justify-content: center;
	align-items: center;
	text-align: start;
	color: #181818;
}

.expected_text {
	font-size: 1.2vw;
	font-family: 'Lato', sans-serif;
	justify-content: center;
	align-items: center;
	text-align: start;
	color: #181818;
	padding-top: 2%;
}

.available__box__pad {
	padding: 2% 0% 2% 0%;
}

.break_pad {
	padding-top: 1%;
	padding-bottom: 1%;
}

.pad_text {
	padding-top: 5vw;
}

.wide {
	width: 18vw;
    height: 18vw;
  	box-shadow: 20px 20px #4a5572;
}
.double-wide {
	width: 36vw;
    height: 18vw;
  	box-shadow: 20px 20px #4a5572;
}

h2 {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  width: 150px;
  margin: auto;
  line-height: 2;
}
.corner-border, .bottom-corner {
  position: relative;

}

/* vertical line */
.corner-border::after {
  content:'';
  position: absolute;
  background-color: #a58d4e;
  width: 3px;
  height: 60%;
  top: -20px;
  left: -30px;
 
}

/* horizontal line */
.corner-border::before{
  content:'';
  position: absolute;
  background-color: #a58d4e;
  width: 25%;
  height: 3px;
  top: -20px;
  left: -30px;
 
}

/* vertical line */
.bottom-corner::after {
  content:'';
  position: absolute;
  background-color: #a58d4e;
  width: 3px;
  height: 50%;
  bottom: 0;
  right:45px;
 
}
/* horizontal line */
.bottom-corner::before {
  content:'';
  position: absolute;
  background-color: #a58d4e;
  width: 20%;
  height: 3px;
  bottom: 0;
  right:45px;
 
}
