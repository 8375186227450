.navit {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #e9f5ff;
  text-align: start;

}

.padright{
  padding-right: 2vw;
}

.padleft{
  padding-left: 4vw;
}

.navit__link {
  margin: 1em;
  font-size: 1.3vw;
  font-family: 'Roboto', sans-serif;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .3em;
  text-indent: .3em;
  color: #181818;
  border-bottom: 3px solid transparent;

}

.navit__link:hover {
  transform: translateY(-2px);
  text-decoration: none;
}

.navit__link--active {
  border-bottom: 3px solid #29b6f6;
  transition: border-bottom .5s ease-in-out;
}

.img__size__navit {
  width: 15vw;
  max-height: 100%;
  padding-left: 5%;

}
